import './App.scss';
import MainChart from './components/MainChart/MainChart';

const App = () => {
  return (
    <>
      <MainChart />
    </>
  );
};

export default App;
